import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Warning from "@material-ui/icons/Warning";
import withRoot from "withRoot";
import ReactLoading from 'react-loading';


import componentsStyle from "assets/jss/material-kit-react/views/components.jsx";

import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";

import ExploreSection from "./Sections/ExploreSection.jsx";
import WorkSection from "./Sections/WorkSection.jsx";
import Footer from "components/Footer/Footer.jsx";

class HomePage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true
    }
  }

  componentDidMount() {
    this.setState({loading: false});
  }

  render() {
    const { companies, classes, ...rest } = this.props;
    return (
      <React.Fragment>

        {this.state.loading ?
          (
            <div style={{
              width: '100px',
              height: '100px',
              position: 'absolute',
              left: '50%',
              marginLeft: '-50px',
            }}>
                <ReactLoading color={'#03a550'} height={'100%'} width={'5%'} />
            </div>
          ) :
          (
          <div>
            <Header
              brand="Welcome To Farmville"
              rightLinks={<HeaderLinks />}
              fixed
              changeColorOnScroll={{
                height: 500,
                color: "white"
              }}
              {...rest}
            />
            <Parallax image={require("assets/img/cover.jpg")}>
              <div className={classes.container}>
                <GridContainer>
                  <GridItem>
                    <div className={classes.brand}>
                      <h1 className={classes.title}>
                        <span style={{backgroundColor: "hsla(149, 96%, 33%, 0.8)"}}>Discover Farmville!</span>
                      </h1>
                      <h3 className={classes.subtitle}>
                        <span style={{backgroundColor: "hsla(149, 96%, 33%, 0.8)"}}>Welcome. That's how we want you to feel.</span>
                      </h3>
                    </div>
                  </GridItem>
                </GridContainer>
              </div>
            </Parallax>
            <div className={classNames(classes.main, classes.mainRaised)}>
              <div className={classes.container}>
                <ExploreSection companies={companies} />
                <WorkSection />
              </div>
            </div>
            <Footer />
          </div>
          )
        }
      </React.Fragment>
    )
  }
}

export default withRoot(withStyles(componentsStyle)(HomePage));
