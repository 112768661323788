import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

import workStyle from "assets/jss/material-kit-react/views/landingPageSections/workStyle.jsx";

class WorkSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
      <section id="contact">
        <div className={classes.section}>
          <GridContainer justify="center">
            <GridItem cs={12} sm={12} md={8}>
              <h2 className={classes.title}>Contact Us</h2>
              <h4 className={classes.description}>
                Do you have any questions? Please do not hesitate to contact us directly.
              </h4>
              <form
                name="contact"
                method="post"
                data-netlify="true"
                data-netlify-honeypot="true"
              >
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="contact" />
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Your Name"
                      id="name"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Your Email"
                      id="email"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <CustomInput
                    labelText="Your Message"
                    id="message"
                    formControlProps={{
                      fullWidth: true,
                      className: classes.textArea
                    }}
                    inputProps={{
                      multiline: true,
                      rows: 5
                    }}
                  />
                  <GridContainer justify="center">
                    <GridItem
                      xs={12}
                      sm={12}
                      md={4}
                      className={classes.textCenter}
                    >
                      <Button color="primary" type="submit" value="Send">
                        Send Message
                      </Button>
                    </GridItem>
                  </GridContainer>
                </GridContainer>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </section>
      </React.Fragment>
    );
  }
}

export default withStyles(workStyle)(WorkSection);
